<template>
  <div>
    <click-to-edit
      v-if="scope.row.scale"
      v-model="scope.row.scale.unit"
      :disabled="!isFieldAvailableForEdit(scope.row, 'scale.unit')"
      placeholder=""
      @input="saveElementPropChange(scope.row, 'scale', {unit:$event})"
    >
    </click-to-edit>
    <div v-else style="color: #b1b1b2;">Сначала необходимо выбрать шкалу</div>
  </div>

</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'element-unit-table-column',
  mixins: [ElementPropTableColumn],
  components: {ClickToEdit},

  props: {},
  data() {
    return {

    }
  },
  computed: {},
  methods: {}
}

</script>

<style>

</style>
